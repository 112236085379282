var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('aside',{directives:[{name:"outside-click",rawName:"v-outside-click",value:(_vm.closeMenu),expression:"closeMenu"}],staticClass:"transition-width group fixed top-0 z-50 flex h-screen transform-gpu flex-col border-r border-slate-400 bg-ink-400 duration-200 lg:sticky lg:left-0",class:[_vm.isOpen ? 'left-0' : '-left-full', _vm.isCollapsed ? 'w-14' : 'w-72']},[(!_vm.toOnboard)?[_c('section',{staticClass:"flex items-center p-2.5 pb-0"},[_c('context-switcher',{attrs:{"is-collapsed":_vm.isCollapsed}})],1),_vm._v(" "),_c('section',{staticClass:"hide-scroll flex-grow space-y-2 p-2.5",class:_vm.isCollapsed ? '' : 'overflow-y-scroll'},[(_vm.canActivateRepo)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isCollapsed ? 'Activate new repository' : '',
          placement: 'right'
        }),expression:"{\n          content: isCollapsed ? 'Activate new repository' : '',\n          placement: 'right'\n        }"}],staticClass:"flex w-full items-center space-x-2 rounded-sm border border-slate-400 bg-ink-300 p-2 leading-none hover:bg-ink-200",on:{"click":function($event){_vm.showAddRepoModal = true}}},[_c('z-icon',{staticClass:"min-h-4 min-w-4",attrs:{"icon":"plus","size":"small","color":"vanilla-400"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],staticClass:"text-sm font-medium"},[_vm._v("Activate new repository")])],1):_vm._e(),_vm._v(" "),_c('nav',{staticClass:"space-y-2",class:{ 'w-full lg:w-56': !_vm.isCollapsed }},[_c('sidebar-item',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.isCollapsed ? 'Home' : '',
            placement: 'right'
          }),expression:"{\n            content: isCollapsed ? 'Home' : '',\n            placement: 'right'\n          }"}],attrs:{"is-collapsed":_vm.isCollapsed,"icon":"home","active":_vm.$route.name === 'provider-owner',"to":_vm.getRoute('')}},[_vm._v("\n          Home\n        ")]),_vm._v(" "),_c('client-only',[_c('pending-adhoc-repos',{attrs:{"is-collapsed":_vm.isCollapsed}})],1),_vm._v(" "),(_vm.viewer.isBetaTester)?_c('sidebar-item',{staticClass:"hidden md:flex",attrs:{"is-collapsed":_vm.isCollapsed,"icon":"search"},on:{"click":function($event){return _vm.$emit('show-palette')}}},[_vm._v("\n          Quick search\n        ")]):_vm._e(),_vm._v(" "),_c('recently-active-repo',{attrs:{"is-collapsed":_vm.isCollapsed}}),_vm._v(" "),(_vm.$config.onPrem && _vm.isViewerSuperadmin)?_c('sidebar-item',{attrs:{"is-collapsed":_vm.isCollapsed,"icon":"window","to":"/control-panel"}},[_vm._v("\n          Enterprise Control Panel\n        ")]):_vm._e()],1)])]:_c('section',{staticClass:"hide-scroll flex-grow"},[_c('div',{staticClass:"border-b border-slate-400",class:[_vm.isCollapsed ? 'py-4 pl-5' : 'py-4 pl-5']},[_c('deep-source-logo',{attrs:{"is-collapsed":_vm.isCollapsed}})],1),_vm._v(" "),_c('nav',{staticClass:"space-y-2 p-2.5"},[(_vm.installationUrl.startsWith('http'))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isCollapsed ? 'Activate new repository' : '',
          placement: 'right'
        }),expression:"{\n          content: isCollapsed ? 'Activate new repository' : '',\n          placement: 'right'\n        }"}],staticClass:"mb-2 flex w-full items-center space-x-2 rounded-sm border border-slate-400 bg-ink-300 p-2 leading-none hover:bg-ink-200",attrs:{"href":_vm.installationUrl}},[_c('z-icon',{staticClass:"min-h-4 min-w-4",attrs:{"icon":"plus","size":"small","color":"vanilla-400"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],staticClass:"text-sm font-medium"},[_vm._v("Activate new repository")])],1):_c('nuxt-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isCollapsed ? 'Activate new repository' : '',
          placement: 'right'
        }),expression:"{\n          content: isCollapsed ? 'Activate new repository' : '',\n          placement: 'right'\n        }"}],staticClass:"mb-2 flex w-full items-center space-x-2 rounded-sm border border-slate-400 bg-ink-300 p-2 leading-none hover:bg-ink-200",attrs:{"to":_vm.installationUrl}},[_c('z-icon',{staticClass:"min-h-4 min-w-4",attrs:{"icon":"plus","size":"small","color":"vanilla-400"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],staticClass:"text-sm font-medium"},[_vm._v("Activate new repository")])],1),_vm._v(" "),_c('sidebar-item',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isCollapsed ? 'Home' : '',
          placement: 'right'
        }),expression:"{\n          content: isCollapsed ? 'Home' : '',\n          placement: 'right'\n        }"}],attrs:{"is-collapsed":_vm.isCollapsed,"icon":"home","to":"/installation/pending"}},[_c('span',[_vm._v("Home")])]),_vm._v(" "),_c('sidebar-item',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isCollapsed ? 'All repositories' : '',
          placement: 'right'
        }),expression:"{\n          content: isCollapsed ? 'All repositories' : '',\n          placement: 'right'\n        }"}],attrs:{"is-collapsed":_vm.isCollapsed,"icon":"layers","to":"/installation/pending"}},[_c('span',[_vm._v("All repositories")])]),_vm._v(" "),_c('sidebar-item',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isCollapsed ? 'Resources' : '',
          placement: 'right'
        }),expression:"{\n          content: isCollapsed ? 'Resources' : '',\n          placement: 'right'\n        }"}],attrs:{"is-collapsed":_vm.isCollapsed,"icon":"resources","to":"https://docs.deepsource.com/docs","target":"_blank","rel":"noopener noreferrer"}},[_c('span',[_vm._v("Resources")])])],1)]),_vm._v(" "),(!_vm.isCollapsed)?_c('section',{staticClass:"p-2.5"},[_c('div',{staticClass:"space-y-2"},[_vm._m(0),_vm._v(" "),_c('a',{staticClass:"auxillary-button w-full gap-x-2.5 p-2",attrs:{"href":"https://go.deepsource.com/ide","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"aspect-square h-4 flex-shrink-0",attrs:{"src":require('~/assets/images/vscode.webp'),"alt":""}}),_vm._v(" "),_c('span',{staticClass:"flex-grow text-left text-xs font-medium leading-none"},[_vm._v("\n          Get VS Code Extension\n        ")]),_vm._v(" "),_c('z-icon',{staticClass:"flex-shrink-0",attrs:{"icon":"arrow-up-right"}})],1)])]):_vm._e(),_vm._v(" "),(
      !_vm.isCollapsed && _vm.owner.maxUsagePercentage >= 100 && Object.keys(_vm.availableUpgradePlans).length
    )?_c('section',{staticClass:"border-t border-slate-400 p-4"},[_c('h4',{staticClass:"mb-2 text-sm font-medium leading-6 text-vanilla-100"},[_vm._v("Usage quota exhausted :(")]),_vm._v(" "),_c('p',{staticClass:"mb-4 text-xs font-normal leading-5 text-vanilla-400"},[_vm._v("\n      You have exhausted your usage quota for this month. Upgrade your plan to keep things\n      running.\n    ")]),_vm._v(" "),_c('z-button',{staticClass:"w-full",attrs:{"button-type":"primary","icon":"zap","label":"Upgrade plan","size":"small"},on:{"click":function($event){_vm.$router.push(_vm.$generateRoute(['settings', 'billing', 'plans'], false, true))}}})],1):_vm._e(),_vm._v(" "),_c('section',{staticClass:"group relative w-full self-end justify-self-end"},[_c('div',{staticClass:"space-y-2 border-t border-slate-400 p-2.5"},[_c('div',{staticClass:"lg:flex"},[_c('support-menu',{attrs:{"is-collapsed":_vm.isCollapsed}})],1),_vm._v(" "),_c('nav',{staticClass:"flex items-center"},[_c('sidebar-item',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.isCollapsed ? 'Dashboard' : '',
            placement: 'right'
          }),expression:"{\n            content: isCollapsed ? 'Dashboard' : '',\n            placement: 'right'\n          }"}],attrs:{"is-collapsed":_vm.isCollapsed,"icon":"dashboard","active":_vm.$route.path === '/me',"to":"/me"}},[_vm._v("\n          Dashboard\n        ")]),_vm._v(" "),(!_vm.isCollapsed)?_c('extras-menu'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"hidden items-center lg:flex",class:{ 'w-full justify-between lg:space-x-1': !_vm.isCollapsed, 'w-8': _vm.isCollapsed }},[_c('user-menu',{attrs:{"is-collapsed":_vm.isCollapsed}})],1)]),_vm._v(" "),_c('div',{staticClass:"border-t border-slate-400 p-4",class:{ 'pt-2': _vm.isChristmasSeason() }},[_c('div',{staticClass:"flex items-center justify-between space-x-2 leading-none"},[(_vm.isChristmasSeason())?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`'Tis the season`),expression:"`'Tis the season`"}],staticClass:"min-h-4 min-w-4 flex-shrink-0 cursor-pointer",attrs:{"src":require("assets/images/christmas-logo.svg"),"alt":"Deepsource logo"}}):_c('z-icon',{staticClass:"min-h-4 min-w-4 flex-shrink-0",attrs:{"icon":"logo","size":"small","color":"vanilla-100"}}),_vm._v(" "),_c('span',{staticClass:"self-end text-xs text-vanilla-300 transition-all",class:{
            'hidden opacity-0 delay-300': _vm.isCollapsed,
            'opacity-1 flex delay-0': !_vm.isCollapsed
          }},[_vm._v("© "+_vm._s(_vm.currentYear)+" DeepSource Corp.")])],1)]),_vm._v(" "),_c('div',{staticClass:"absolute -right-2.5 -top-2.5 hidden md:group-hover:block"},[_c('button',{staticClass:"group flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-ink-100 hover:bg-slate focus:outline-none",on:{"click":_vm.toggleSidebarCollapse}},[_c('z-icon',{staticClass:"transform-gpu transition-transform duration-300",class:_vm.isCollapsed ? 'rotate-180' : '',attrs:{"icon":"chevron-left","size":"small","color":"vanilla-400"}})],1)])]),_vm._v(" "),_c('add-repo-modal',{attrs:{"show-modal":_vm.showAddRepoModal},on:{"close":function($event){_vm.showAddRepoModal = false}}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center gap-x-2"},[_c('div',{staticClass:"animate-bg rounded-full p-px bg-gradient-ocean"},[_c('div',{staticClass:"rounded-[9998px] bg-ink-300 px-1.5 py-1 text-[8px] font-medium uppercase leading-none tracking-[0.32px]"},[_c('span',{staticClass:"animate-bg bg-clip-text text-transparent bg-gradient-ocean"},[_vm._v("New")])])]),_vm._v(" "),_c('div',{staticClass:"animate-bg bg-clip-text text-xs font-medium uppercase leading-none tracking-[0.48px] text-transparent bg-gradient-ocean"},[_vm._v("\n          Autofix™ AI\n        ")])])
}]

export { render, staticRenderFns }