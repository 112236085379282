var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.isLink && !_vm.disabled ? 'a' : 'button',{tag:"component",staticClass:"inline-flex items-center font-medium transition-colors duration-300 ease-in-out rounded-sm z-btn focus:outline-none whitespace-nowrap",class:{
    [_vm.textColor]: _vm.textColor,
    [_vm.justifyClass]: _vm.justifyClass,
    [`z-btn--${_vm.color}`]: _vm.color,
    'w-full': _vm.fullWidth,

    // Button Size & Spacing Styles
    'p-0': _vm.isLink || _vm.icon,
    'h-6 px-2 py-0.5 text-xs space-x-1 leading-loose': _vm.size === 'x-small' && !_vm.iconOnly,
    'h-8 px-4 py-1 text-xs space-x-1 leading-loose': _vm.size === 'small' && !_vm.iconOnly,
    'h-10 px-4 py-2 text-base space-x-2 leading-8': _vm.size === 'medium' && !_vm.iconOnly,
    'h-12 px-4 py-2.5 text-lg space-x-2.5 leading-9': _vm.size === 'large' && !_vm.iconOnly,
    'h-13 px-4 py-3 text-lg space-x-3 leading-9': _vm.size === 'xlarge' && !_vm.iconOnly,

    // Set width of button when just icon is used
    'h-6 w-6': _vm.size === 'x-small' && _vm.iconOnly,
    'h-8 w-8': _vm.size === 'small' && _vm.iconOnly,
    'h-10 w-10': _vm.size === 'medium' && _vm.iconOnly,
    'h-12 w-12': _vm.size === 'large' && _vm.iconOnly,
    'h-13 w-13': _vm.size === 'xlarge' && _vm.iconOnly,

    // Button Base Styles
    'font-normal': ['link', 'ghost'].includes(_vm.buttonStyle),
    'bg-juniper': _vm.buttonStyle === 'primary',
    'bg-ink-300': _vm.buttonStyle === 'secondary',
    'bg-ink-200': _vm.buttonStyle === 'secondary-bright',
    'bg-cherry': _vm.buttonStyle === 'danger',
    'bg-honey': _vm.buttonStyle === 'warning',

    // Button Hover Styles
    'hover:underline': _vm.buttonStyle === 'link' && !_vm.disabled,
    'hover:bg-juniper-600': _vm.buttonStyle === 'primary' && !_vm.disabled,
    'hover:bg-ink-200': ['secondary', 'ghost'].includes(_vm.buttonStyle) && !_vm.disabled,
    'hover:bg-ink-100': _vm.buttonStyle === 'secondary-bright' && !_vm.disabled,
    'hover:bg-cherry-600': _vm.buttonStyle === 'danger' && !_vm.disabled,
    'hover:bg-honey-400': _vm.buttonStyle === 'warning' && !_vm.disabled,

    // Disabled Styles
    'opacity-50 cursor-not-allowed': _vm.disabled,
    'font-normal text-slate': ['link', 'ghost'].includes(_vm.buttonStyle) && _vm.disabled
  },attrs:{"href":_vm.isLink ? (_vm.to || '#') : false,"disabled":_vm.disabled,"type":_vm.isLink ? 'link' : _vm.type},on:{"click":_vm.clicked}},[(_vm.icon)?_c('z-icon',{class:{
      'mr-0.5': _vm.size == 'x-small' && !_vm.iconOnly,
      'mr-1.5': _vm.size == 'small' && !_vm.iconOnly,
      'mr-2': _vm.size == 'medium' && !_vm.iconOnly,
      'mr-2.5': _vm.size == 'large' && !_vm.iconOnly,
      'mr-3': _vm.size == 'xlarge' && !_vm.iconOnly,
      'animate-spin': _vm.isLoading
    },attrs:{"icon":_vm.isLoading ? 'spin-loader' : _vm.icon,"color":_vm.iconColorComputed,"size":_vm.iconSizeToken}}):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [(_vm.isLoading && _vm.loadingLabel)?[_vm._v(_vm._s(_vm.loadingLabel))]:[_vm._v(_vm._s(_vm.label))]]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }